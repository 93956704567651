import Head from "next/head";

export default function SEO({
  title,
  description,
  keywords,
  url,
  ogImage,
  children,
}) {
  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      <meta name="title" content={title} />

      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Shaadi Vines" />

      <meta property="og:type" content="website" />
      {ogImage && (
        <meta property="og:image" itemProp="image" content={ogImage} />
      )}
      {ogImage && <link itemProp="thumbnailUrl" href={ogImage} />}
      <meta property="og:image:type" content="image/webp" />
      <meta property="og:image:width" content="640" />
      <meta property="og:image:height" content="300" />

      {/*<!-- Google / Search Engine Tags -->*/}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {ogImage && <meta itemProp="image" content={ogImage} />}

      {/*<!-- Facebook Meta Tags -->*/}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/*<!-- Twitter Meta Tags -->*/}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      <meta name="twitter:card" content="summary_large_image" />
      {children}
    </Head>
  );
}

const reviews = [
  {
    name: "Priya Sharma",
    reviewBody:
      "Absolutely loved how our wedding invitation turned out! The customization options are endless and the final result was stunning.",
  },
  {
    name: "Rahul Verma",
    reviewBody:
      "The digital invitations exceeded our expectations. Modern yet traditional - perfect for our fusion wedding!",
  },
  {
    name: "Meera Patel",
    reviewBody:
      "Such a seamless experience creating our wedding invitation. The designs are elegant and the service is top-notch.",
  },
  {
    name: "Arjun Singh",
    reviewBody:
      "InviteDekho made our wedding invitation process so easy. Our guests were impressed with the professional quality.",
  },
  {
    name: "Neha Kapoor",
    reviewBody:
      "Beautiful designs that perfectly capture the essence of Indian weddings. The attention to detail is remarkable.",
  },
  {
    name: "Vikram Malhotra",
    reviewBody:
      "The perfect blend of tradition and technology. Our families loved the creative approach to wedding invitations.",
  },
  {
    name: "Anjali Reddy",
    reviewBody:
      "Exceptional service and beautiful designs. The platform is user-friendly and the results are spectacular.",
  },
  {
    name: "Karan Mehta",
    reviewBody:
      "The customization options allowed us to create exactly what we envisioned. Highly recommend for any special occasion!",
  },
  {
    name: "Pooja Gupta",
    reviewBody:
      "Outstanding quality and creative designs. Our wedding invitation stood out thanks to InviteDekho.",
  },
  {
    name: "Aditya Kumar",
    reviewBody:
      "The digital invitations were a hit among our guests. Modern, elegant, and perfectly executed.",
  },
  {
    name: "Ritu Desai",
    reviewBody:
      "Couldn't be happier with our choice. The designs are contemporary yet maintain traditional elements beautifully.",
  },
  {
    name: "Sanjay Joshi",
    reviewBody:
      "Excellent platform for creating memorable invitations. The process was smooth and the results were fantastic.",
  },
  {
    name: "Divya Krishnan",
    reviewBody:
      "The perfect solution for modern Indian weddings. Our invitation was elegant, interactive, and impressive.",
  },
  {
    name: "Amit Shah",
    reviewBody:
      "Great value for money! The quality and design options exceeded our expectations.",
  },
  {
    name: "Sneha Iyer",
    reviewBody:
      "The invitations perfectly captured our wedding theme. Received countless compliments from our guests.",
  },
  {
    name: "Rajesh Khanna",
    reviewBody:
      "Professional, elegant, and timely service. Exactly what we needed for our special day.",
  },
  {
    name: "Maya Srinivasan",
    reviewBody:
      "The designs are spectacular and the customization process is intuitive. Couldn't ask for better!",
  },
  {
    name: "Vivek Chopra",
    reviewBody:
      "Outstanding digital invitations that impressed all our guests. Modern approach with traditional values.",
  },
  {
    name: "Anita Deshmukh",
    reviewBody:
      "The perfect blend of creativity and tradition. Our invitation was unique and memorable.",
  },
  {
    name: "Rohit Menon",
    reviewBody:
      "Exceptional quality and service. The platform made creating our dream invitation effortless.",
  },
];

const getRandomReview = () => {
  const randomIndex = Math.floor(Math.random() * reviews.length);
  return reviews[randomIndex];
};

// Update the getReviewAndRating function to use consistent ratings with the review
const getReviewAndRating = () => {
  return {
    ratingValue: `${(Math.random() * (5 - 4) + 4).toFixed(1)}`,
    reviewCount: `${Math.floor(Math.random() * (200 - 150 + 1) + 150)}`,
  };
};

const InviteDekhoSchema = () => {
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: `{
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Wedding Invitations Video",
            "image": "https://www.invitedekho.com/assets/home/hero-image.webp",
            "description": "Elegant and customizable Indian Wedding Website Invitations to match your wedding theme and style.",
            "url": "https://www.invitedekho.com/",
            "brand": {
              "@type": "Brand",
              "name": "InviteDekho"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.invitedekho.com/",
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "152"
            },
            "sku": "IW-WEB-001"
          }`,
      }}
    ></script>
  );
};
const VideoInvitationSchema = () => {
  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Indian Wedding Website Invitations",
            "image":
              "https://id-prod-designs.s3.ap-south-1.amazonaws.com/videos/VID-A058/VID-A058-thumbnail.jpg",
            "description":
              "Elegant and customizable Indian Wedding Website Invitations to match your wedding theme and style.",
            "url": "https://www.invitedekho.com/designs/wedding-video-invitation/",
            "brand": {
              "@type": "Brand",
              "name": "InviteDekho",
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.invitedekho.com/designs/wedding-video-invitation/",
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition",
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "152",
            },
            "sku": "IW-WEB-001",
          }),
        }}
      ></script> */}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Indian Wedding Video Invitations",
              "url": "https://www.invitedekho.com/designs/wedding-video-invitation/",
              "image": "https://id-prod-designs.s3.ap-south-1.amazonaws.com/videos/VID-A058/VID-A058-thumbnail.jpg", 
              "description": "Explore beautifully designed Indian Wedding Video Invitations to create a lasting impression. Fully customizable to suit your wedding theme.",
              "brand": {
                "@type": "Brand",
                "name": "InviteDekho"
              }
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5",
              "worstRating": "3"
            },
            "author": {
              "@type": "Person",
              "name": "Anjali Gupta"
            },
            "reviewBody": "The Indian Wedding Website Invitations by InviteDekho are truly spectacular! They are customizable, vibrant, and perfectly suited for traditional and modern themes. Highly recommend for your big day."
          }),
        }}
      ></script>
    </>
  );
};
const BirthdayVideoInvitationSchema = () => {
  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Indian Wedding Website Invitations",
            "image":
              "https://id-prod-designs.s3.ap-south-1.amazonaws.com/videos/VID-A058/VID-A058-thumbnail.jpg",
            "description":
              "Elegant and customizable Indian Wedding Website Invitations to match your wedding theme and style.",
            "url": "https://www.invitedekho.com/designs/birthday-video-invitation/",
            "brand": {
              "@type": "Brand",
              "name": "InviteDekho",
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.invitedekho.com/designs/birthday-video-invitation/",
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition",
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "152",
            },
            "sku": "IW-WEB-001",
          }),
        }}
      ></script> */}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Indian Wedding Video Invitations",
              "url": "https://www.invitedekho.com/designs/birthday-video-invitation/",
              "image": "https://id-prod-designs.s3.ap-south-1.amazonaws.com/videos/VID-A058/VID-A058-thumbnail.jpg", 
              "description": "Explore beautifully designed Indian Wedding Video Invitations to create a lasting impression. Fully customizable to suit your wedding theme.",
              "brand": {
                "@type": "Brand",
                "name": "InviteDekho"
              }
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5",
              "worstRating": "3"
            },
            "author": {
              "@type": "Person",
              "name": "Anjali Gupta"
            },
            "reviewBody": "The Indian Wedding Website Invitations by InviteDekho are truly spectacular! They are customizable, vibrant, and perfectly suited for traditional and modern themes. Highly recommend for your big day."
          }),
        }}
      ></script>
    </>
  );
};
const EngagementVideoInvitationSchema = () => {
  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Indian Wedding Website Invitations",
            "image":
              "https://id-prod-designs.s3.ap-south-1.amazonaws.com/videos/VID-A058/VID-A058-thumbnail.jpg",
            "description":
              "Elegant and customizable Indian Wedding Website Invitations to match your wedding theme and style.",
            "url": "https://www.invitedekho.com/designs/engagement-video-invitation/",
            "brand": {
              "@type": "Brand",
              "name": "InviteDekho",
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.invitedekho.com/designs/engagement-video-invitation/",
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition",
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "152",
            },
            "sku": "IW-WEB-001",
          }),
        }}
      ></script> */}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Indian Wedding Video Invitations",
              "url": "https://www.invitedekho.com/designs/engagement-video-invitation/",
              "image": "https://id-prod-designs.s3.ap-south-1.amazonaws.com/videos/VID-A058/VID-A058-thumbnail.jpg", 
              "description": "Explore beautifully designed Indian Wedding Video Invitations to create a lasting impression. Fully customizable to suit your wedding theme.",
              "brand": {
                "@type": "Brand",
                "name": "InviteDekho"
              }
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5",
              "worstRating": "3"
            },
            "author": {
              "@type": "Person",
              "name": "Anjali Gupta"
            },
            "reviewBody": "The Indian Wedding Website Invitations by InviteDekho are truly spectacular! They are customizable, vibrant, and perfectly suited for traditional and modern themes. Highly recommend for your big day."
          }),
        }}
      ></script>
    </>
  );
};
const PdfInvitationSchema = () => {
  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Indian Wedding Website Invitations",
            "image":
              "https://id-prod-designs.s3.ap-south-1.amazonaws.com/pdf/35/sample/2.jpg",
            "description":
              "Elegant and customizable Indian Wedding Website Invitations to match your wedding theme and style.",
            "url": "https://www.invitedekho.com/designs/wedding-pdf-invitation/",
            "brand": {
              "@type": "Brand",
              "name": "InviteDekho",
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.invitedekho.com/designs/wedding-pdf-invitation/",
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition",
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "152",
            },
            "sku": "IW-WEB-001",
          }),
        }}
      ></script> */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Indian Wedding PDF Invitations",
              "url": "https://www.invitedekho.com/designs/wedding-pdf-invitation/",
              "image": "https://id-prod-designs.s3.ap-south-1.amazonaws.com/pdf/35/sample/2.jpg", 
              "description": "Explore beautifully designed Indian Wedding PDF Invitations to create a lasting impression. Fully customizable to suit your wedding theme.",
              "brand": {
                "@type": "Brand",
                "name": "InviteDekho"
              }
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5",
              "worstRating": "3"
            },
            "author": {
              "@type": "Person",
              "name": "Anjali Gupta"
            },
            "reviewBody": "The Indian Wedding Website Invitations by InviteDekho are truly spectacular! They are customizable, vibrant, and perfectly suited for traditional and modern themes. Highly recommend for your big day."
          }),
        }}
      ></script>
    </>
  );
};
const WebsiteInvitationSchema = () => {
  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Indian Wedding Website Invitations",
            "image": "https://www.invitedekho.com/images/wedding-website-invitations.jpg",
            "description": "Elegant and customizable Indian Wedding Website Invitations to match your wedding theme and style.",
            "url": "https://www.invitedekho.com/designs/wedding-website-invitation/",
            "brand": {
              "@type": "Brand",
              "name": "InviteDekho"
            },
            "offers": {
              "@type": "Offer",
              "url": "https://www.invitedekho.com/designs/wedding-website-invitation/",
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.8",
              "reviewCount": "152"
            },
            "sku": "IW-WEB-001"
          }),
        }}
      ></script> */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            "itemReviewed": {
              "@type": "Product",
              "name": "Indian Wedding Website Invitations",
              "url": "https://www.invitedekho.com/designs/wedding-website-invitation/",
              "image": "https://www.invitedekho.com/images/wedding-website-invitations.jpg", 
              "description": "Explore beautifully designed Indian Wedding Website Invitations to create a lasting impression. Fully customizable to suit your wedding theme.",
              "brand": {
                "@type": "Brand",
                "name": "InviteDekho"
              }
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5",
              "worstRating": "3"
            },
            "author": {
              "@type": "Person",
              "name": "Anjali Gupta"
            },
            "reviewBody": "The Indian Wedding Website Invitations by InviteDekho are truly spectacular! They are customizable, vibrant, and perfectly suited for traditional and modern themes. Highly recommend for your big day."
          }),
        }}
      ></script>
    </>
  );
};
const InvitationDetailsSchema = ({ details }) => {
  const review = getRandomReview();
  const { name, image, description, url, code } = details;
  return (
    <>
      {/* <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            name: name, // Remove template literals
            image: image,
            description: description,
            url: url,
            brand: {
              "@type": "Brand",
              "name": "InviteDekho"
            },
            offers: {
              "@type": "Offer",
              url: url,
              "priceCurrency": "INR",
              "availability": "https://schema.org/InStock",
              "itemCondition": "https://schema.org/NewCondition"
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: getReviewAndRating().ratingValue,
              reviewCount: getReviewAndRating().reviewCount
            },
            sku: code
          })
        }}
      /> */}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Review",
            itemReviewed: {
              "@type": "Product",
              name: name,
              url: url,
              image: image,
              description: description,
              brand: {
                "@type": "Brand",
                "name": "InviteDekho"
              }
            },
            reviewRating: {
              "@type": "Rating",
              "ratingValue": "4.8",
              "bestRating": "5",
              "worstRating": "3"
            },
            author: {
              "@type": "Person",
              name: review.name
            },
            reviewBody: review.reviewBody
          })
        }}
      />
    </>
  );
};
